import { Cards } from './GeneratorCards'
import { datcatsCardData, sagamonkesCardData, monkeblockCardData} from './data/cardData'

function Hero() {
    return (
        <div className='cards'>
            <Cards path={datcatsCardData.path} image={datcatsCardData.image} alt={datcatsCardData.alt} buttonText={datcatsCardData.buttonText} />
            <Cards path={sagamonkesCardData.path} image={sagamonkesCardData.image} alt={sagamonkesCardData.alt} buttonText={sagamonkesCardData.buttonText} />
            <Cards path={monkeblockCardData.path} image={monkeblockCardData.image} alt={monkeblockCardData.alt} buttonText={monkeblockCardData.buttonText} />
        </div>
    )
}

export default Hero
import { Link } from 'react-router-dom';

export function Cards(props) {
    return (
        <div className="generator_card">
            {/* <Link to={props.path}>
                <img className="cardImage" src={props.image} alt={props.alt} />
            </Link> */} 
            <img 
                className="cardImage" 
                src={props.image} 
                alt={props.alt} 
                draggable='false' />
            <Link to={props.path} className="pathButton">{props.buttonText}</Link>
        </div>
    )
}

export default Cards
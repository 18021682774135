import React from 'react';
import TextSection from '../components/SubpageText'
import GenerateSection from '../components/Generate'
import ImageSection from '../components/OutputImage'
import PoweredFooter from '../components/Footer'

import { monkeblockPageData } from '../components/data/pageData.js'

import '../App.css';

export function MonkeBlockPage() {
    return (
        <div className='app'>       
            <div className='hero'>
                <div className='text_left'>
                        <TextSection headline={monkeblockPageData.headline} description={monkeblockPageData.description} />
                        <GenerateSection placeholderImage={monkeblockPageData.placeholderImage} styles={monkeblockPageData.styles} needStyles={monkeblockPageData.needStyles} generationData={monkeblockPageData.generationData} />
                </div>
                    <ImageSection placeholderImage={monkeblockPageData.placeholderImage} imageClass={monkeblockPageData.imageClass} />
            </div>
            <PoweredFooter />
        </div>
    )
}

export function MonkeBlockPageMobile() {
    return (
        <div className='app'>       
            <div className='hero'>
                        <TextSection headline={monkeblockPageData.headline} description={monkeblockPageData.description} />
                        <ImageSection placeholderImage={monkeblockPageData.placeholderImage} imageClass={monkeblockPageData.imageClass} />
                        <GenerateSection placeholderImage={monkeblockPageData.placeholderImage} styles={monkeblockPageData.styles} needStyles={monkeblockPageData.needStyles} generationData={monkeblockPageData.generationData} />
            </div>
        </div>
    )
}
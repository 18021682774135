import React from 'react';
import TextSection from '../components/SubpageText'
import GenerateSection from '../components/Generate'
import ImageSection from '../components/OutputImage'
import Footer from '../components/Footer'

import { sagamonkesPageData } from '../components/data/pageData.js'

import '../App.css';

export function SagamonkesPage() {
    return (
        <div className='app'>       
            <div className='hero'>
                <div className='text_left'>
                        <TextSection headline={sagamonkesPageData.headline} description={sagamonkesPageData.description} />
                        <GenerateSection placeholderImage={sagamonkesPageData.placeholderImage} styles={sagamonkesPageData.styles} needStyles={sagamonkesPageData.needStyles} generationData={sagamonkesPageData.generationData} />
                </div>
                    <ImageSection placeholderImage={sagamonkesPageData.placeholderImage} imageClass={sagamonkesPageData.imageClass} />
            </div>
            <Footer />
        </div>
    )
}

export function SagamonkesPageMobile() {
    return (
        <div className='app'>       
            <div className='hero'>
                        <TextSection headline={sagamonkesPageData.headline} description={sagamonkesPageData.description} />
                        <ImageSection placeholderImage={sagamonkesPageData.placeholderImage} imageClass={sagamonkesPageData.imageClass} />
                        <GenerateSection placeholderImage={sagamonkesPageData.placeholderImage} styles={sagamonkesPageData.styles} needStyles={sagamonkesPageData.needStyles} generationData={sagamonkesPageData.generationData} />
            </div>
        </div>
    )
}
import '../App.css';

export function NoPage() {
    return (
        <div className='app'>    
        <h1 className='headerText'>Error 404: Not Found</h1>
        </div>
    )
}

export function NoPageMobile() {
    return (
        <div className='app'>    
        <h1 className='headerText'>Error 404: Not Found</h1>
        </div>
    )
}
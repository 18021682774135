import MediaQuery from 'react-responsive'

function Header() {
    return (
        <div className='header'>
            <p
                className='headerText'
                id='topHeaderText'>
                    <MediaQuery minWidth={768}>
                    CHOOSE GENERATOR
                    </MediaQuery>

                    <MediaQuery maxWidth={767}>
                    CHOOSE <br/> GENERATOR
                    </MediaQuery>
                </p>
        </div>
    )
}

export default Header
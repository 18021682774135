import React from 'react';

export default function Options({ styles }) {
    return (
        <>
            {styles.map(style => (
                <option key={style.value} value={style.value}>
                    {style.optionText}
                </option>
            ))}
        </>
    );
}

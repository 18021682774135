import React from 'react';
import MediaQuery from 'react-responsive'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { HomePage, HomePageMobile } from './pages/Home'
import { DatcatsPage, DatcatsPageMobile } from './pages/Datcats'
import { SagamonkesPage, SagamonkesPageMobile } from './pages/Sagamonkes'
import { NoPage, NoPageMobile } from './pages/NoPage';
import { MonkeBlockPage, MonkeBlockPageMobile } from './pages/MonkeBlock';

/*export default function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path='home' element={<HomePage />} />
                    <Route path='datcats' element={<DatcatsPage />} />
                    <Route path='sagamonkes' element={<SagamonkesPage />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}*/


function DefaultScreen() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path='home' element={<HomePage />} />
                    <Route path='datcats' element={<DatcatsPage />} />
                    <Route path='sagamonkes' element={<SagamonkesPage />} />
                    <Route path='monkeblock' element={<MonkeBlockPage />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

function MobileScreen() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomePageMobile />} />
                    <Route path='home' element={<HomePageMobile />} />
                    <Route path='datcats' element={<DatcatsPageMobile />} />
                    <Route path='sagamonkes' element={<SagamonkesPageMobile />} />
                    <Route path='monkeblock' element={<MonkeBlockPageMobile />} />
                    <Route path="*" element={<NoPageMobile />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

const App = () => {
    return (
        <div className='app'>

            <MediaQuery minWidth={768}>
                <DefaultScreen />
            </MediaQuery>

            <MediaQuery maxWidth={767}>
                <MobileScreen />
            </MediaQuery>

        </div>
    );
}

export default App;
import React from 'react';
import TextSection from '../components/SubpageText'
import GenerateSection from '../components/Generate'
import ImageSection from '../components/OutputImage'
import PoweredFooter from '../components/Footer'

import { datcatsPageData } from '../components/data/pageData.js'

import '../App.css';

export function DatcatsPage() {
    return (
        <div className='app'>       
            <div className='hero'>
                <div className='text_left'>
                        <TextSection headline={datcatsPageData.headline} description={datcatsPageData.description} />
                        <GenerateSection placeholderImage={datcatsPageData.placeholderImage} styles={datcatsPageData.styles} needStyles={datcatsPageData.needStyles} generationData={datcatsPageData.generationData} />
                </div>
                    <ImageSection placeholderImage={datcatsPageData.placeholderImage} imageClass={datcatsPageData.imageClass} />
            </div>
            <PoweredFooter />
        </div>
    )
}

export function DatcatsPageMobile() {
    return (
        <div className='app'>       
            <div className='hero'>
                        <TextSection headline={datcatsPageData.headline} description={datcatsPageData.description} />
                        <ImageSection placeholderImage={datcatsPageData.placeholderImage} imageClass={datcatsPageData.imageClass} />
                        <GenerateSection placeholderImage={datcatsPageData.placeholderImage} styles={datcatsPageData.styles} needStyles={datcatsPageData.needStyles} generationData={datcatsPageData.generationData} />
            </div>
        </div>
    )
}
export const datcatsCardData = {
    "image": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Datcat_Showcase.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvRGF0Y2F0X1Nob3djYXNlLnBuZyIsImlhdCI6MTcxMzI5MzA4MCwiZXhwIjoxNzQ0ODI5MDgwfQ.okMVBQyElmzG22fyeJcttTD174H5O_Hs-pT_Vyc_hIA&t=2024-04-16T18%3A44%3A40.025Z",
    "alt": "datcats",
    "buttonText": "DATCATS",
    "path": "/datcats"
};

export const sagamonkesCardData = {
    "image": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Saga_Monke_Wallpaper_Placeholder.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvU2FnYV9Nb25rZV9XYWxscGFwZXJfUGxhY2Vob2xkZXIucG5nIiwiaWF0IjoxNzEzMjkzMzU4LCJleHAiOjE3NDQ4MjkzNTh9._v7LuQjKHx9PqlLMgvOzMjkjksXqYECQgFIbe_UOKVA&t=2024-04-16T18%3A49%3A18.172Z",
    "alt": "sagamonkes",
    "buttonText": "SAGA MONKES",
    "path": "/sagamonkes"
};

export const monkeblockCardData = {
    "image": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Monke_Block_Placeholder.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvTW9ua2VfQmxvY2tfUGxhY2Vob2xkZXIucG5nIiwiaWF0IjoxNzEzMjkzNDgwLCJleHAiOjE3NDQ4Mjk0ODB9.egED3GANHLnlQjj7jRRZB_mXXP02eLxK2XPqJAQ0wo4&t=2024-04-16T18%3A51%3A20.647Z",
    "alt": "monkeblock",
    "buttonText": "MONKEBLOCK",
    "path": "/monkeblock"
};
import { ImageGenerationScript } from './scripts/imageGenerationScript'
import Options from './scripts/styleOptions'

function GenerateStylesOptional(props) {
    const anyStyles = props.needStyles;
    if (anyStyles) {
        return (
            <select
                className={props.needStyles ? 'input' : 'input_higher'}
                defaultValue=""
                id='chooseStyle'>
                    <Options styles={props.styles} />
            </select>
        )
    }
} 

export default function GenerateSection(props) {
    return (
        <div className='text_lower'>
            <form 
            className='form'
            onSubmit={(e) => ImageGenerationScript(e, props.generationData)}>
                <input 
                    type='number'
                    className={props.needStyles ? 'input' : 'input_higher'}
                    placeholder='EXAMPLE: #294'
                    id='input-nftId' 
                    autoComplete='off'
                    />
                <br/>
                <GenerateStylesOptional needStyles={props.needStyles} styles={props.styles} />
                <a 
                    href={props.placeholderImage} 
                    id="link"
                    alt='outputImage'>
                </a>
                <button
                    type='submit'
                    id='submit_button' 
                    className={props.needStyles ? 'submit_button' : 'submit_button_higher'}
                    >GENERATE</button>
                    <p className='outputText hide' id='text-output'>TEST</p>
            </form>
                    </div>
)
}
import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer'

import '../App.css';

export function HomePage() {
    return (
        <div className='app'>    
            <Header />
            <Hero />
            <Footer />
        </div>
    )
}

export function HomePageMobile() {
    return (
        <div className='app'>    
            <div className='homeHero'>
            <Header />
            <Hero />
            </div>
        </div>
    )
}
function PoweredFooter() {
    return (
    <div className='footer'>
        <p
            className='poweredText'
            id='bottomCreditText'
            >POWERED BY BOBOIS CLUBHOUSE
            </p>
    </div>
)
}

export default PoweredFooter
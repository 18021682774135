export function ImageGenerationScript(e, generationData) {
    e.preventDefault();
      const nftID = document.querySelector("#input-nftId");
      const changeImage = document.querySelector("#output");
      const chosenStyle = document.querySelector("#chooseStyle");
      /*const chosenStyle = {
        "value": "probably something"
      }*/
      const textOutput = document.querySelector("#text-output");
      const updateButton = document.querySelector("#submit_button");
      const updateButtonHigher = document.querySelector("#submit_button_higher");
      const link = document.querySelector("#link")
      
      console.log(generationData.needStyles)

      function whatStyle(needStyles) {
        return needStyles ? chosenStyle.value : `"style": "none"`
      }

      console.log(whatStyle(generationData.needStyles))
    
        //console.log(nftID.value.length)
        //console.log(chosenStyle.value)
        const postData = {
          "nft_id": nftID.value,
          "style": whatStyle(generationData.needStyles)
        };
        if (nftID.value.length === 0 || nftID.value > generationData.maxValue) {
          textOutput.classList.remove("hide")
          console.log("Submission was empty. Please input your datcat ID")
          document.querySelector("#text-output").textContent = "PLEASE INPUT A VALID DATCAT ID"
        };
        /*if (chosenStyle.value === "") {
          textOutput.classList.remove("hide")
          console.log("Submission was empty. Please input your datcat ID")
          document.querySelector("#text-output").textContent = "PLEASE FILL ALL THE BOXES"
        };*/

        if (nftID.value.length > 0 && nftID.value <= generationData.maxValue /*&& chosenStyle.value.length > 1*/) {
          textOutput.classList.remove("hide")
          document.querySelector("#text-output").textContent = "LOADING..."

          fetch(`https://sleepy-frog-wetsuit.cyclic.app/api/projects/${generationData.route}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
          })
          .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.text();
          })
          .then(data => {
          console.log(data);
          changeImage.src = data
          textOutput.classList.add("hide");
          nftID.classList.add("hide");
          generationData.needStyles ? chosenStyle.classList.add("hide") : textOutput.classList.add("test");

          fetch(data)
          .then(response => response.blob())
          .then(blob => {
            updateButton.textContent = "DOWNLOAD"
            updateButton.removeAttribute("type")
            updateButton.addEventListener("click", event => {
              event.preventDefault();
              console.log("Clicked DOWNLOAD");
              link.click();
            });
            updateButton.id = "testButton"
          link.href = URL.createObjectURL(blob);
          link.download = `${generationData.downloadName}.png`;

          updateButtonHigher.textContent = "DOWNLOAD"
          updateButtonHigher.removeAttribute("type")
          updateButtonHigher.addEventListener("click", event => {
              event.preventDefault();
              console.log("Clicked DOWNLOAD");
              link.click();
            });
            updateButtonHigher.id = "testButton"
          link.href = URL.createObjectURL(blob);
          link.download = `${generationData.downloadName}.png`;
          })
          .catch(console.error);

          })
          .catch(error => console.error('Error:', error));
          };
};
export default function ImageSection(props) {
    return (
        <div className='image_right'>
            <img 
                className={props.imageClass} 
                id='output' 
                alt='outputImage'
                src={props.placeholderImage}
                draggable='false'/>
        </div>
)
}
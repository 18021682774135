import MediaQuery from 'react-responsive'

export default function TextSection(props) {
    return (
        <div className='text_upper'>
                        <h1 className='headline'>{props.headline} <br/> GENERATOR</h1>
                        <MediaQuery minWidth={768}>
                        <div className='list'>
                            <p className="list_text">• INPUT YOUR {props.description} ID</p>
                            <p className="list_text">• HIT THE GENERATE BUTTON</p>
                            <p className="list_text">• DOWNLOAD</p>

                        </div>
                        </MediaQuery>

                        <MediaQuery maxWidth={767}>
                            
                        </MediaQuery>
                    </div>


        /*<div className='text_upper'>
                        <h1 className='headline'>WALLPAPER <br/> GENERATOR</h1>
                        <div className='list'>
                            <p className="list_text">• INPUT YOUR SAGA MONKE ID</p>
                            <p className="list_text">• HIT THE GENERATE BUTTON</p>
                            <p className="list_text">• DOWNLOAD</p>

                        </div>
                    </div>*/
)
}
export const datcatsPageData = {
    "headline": "DATCATS",
    "description": "DATCAT",
    "path": "/datcats",
    "styles": [
        {
            "value": "",
            "optionText": "CHOOSE STYLE"
        },
        {
            "value": "background",
            "optionText": "BACKGROUND"
        },
        {
            "value": "transparent",
            "optionText": "TRANSPARENT"
        },
    ],
    "needStyles": true,
    "placeholderImage": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Datcat_Showcase.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvRGF0Y2F0X1Nob3djYXNlLnBuZyIsImlhdCI6MTcxMzI5MzA4MCwiZXhwIjoxNzQ0ODI5MDgwfQ.okMVBQyElmzG22fyeJcttTD174H5O_Hs-pT_Vyc_hIA&t=2024-04-16T18%3A44%3A40.025Z",
    "imageClass": "output-image-datcats",

    "generationData": {
        "maxValue": 4999,
        "route": "datcats",
        "downloadName": "datcats_cheers"
        ,"needStyles": true
    }
};

export const sagamonkesPageData = {
    "headline": "MONKE",
    "description": "SAGA MONKE",
    "path": "/sagamonkes",
    "styles": [
        {
            "value": "",
            "optionText": "CHOOSE DEVICE"
        },
        {
            "value": "saga",
            "optionText": "SAGA"
        },
    ],
    "needStyles": true,
    "placeholderImage": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Saga_Wallpaper_Placeholder.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvU2FnYV9XYWxscGFwZXJfUGxhY2Vob2xkZXIucG5nIiwiaWF0IjoxNzEzMjkzNTA0LCJleHAiOjE3NDQ4Mjk1MDR9.uNP_dA8B1qIICj8ur5S21XCT7Zc0bn_v_dny8dril8I&t=2024-04-16T18%3A51%3A44.499Z",
    "imageClass": "output-image-sagamonkes",

    "generationData": {
        "maxValue": 8880,
        "route": "sagamonkes",
        "downloadName": "sagamonke_wallpaper",
        "needStyles": true
    }
};

export const monkeblockPageData = {
    "headline": "BLOCK",
    "description": "MONKE",
    "path": "/monkeblock",
    "styles": [
        {
            "value": "",
            "optionText": "CHOOSE STYLE"
        },
        {
            "value": "background",
            "optionText": "BACKGROUND"
        },
        {
            "value": "transparent",
            "optionText": "TRANSPARENT"
        },
    ],
    "needStyles": false,
    "placeholderImage": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Monke_Block_Placeholder.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvTW9ua2VfQmxvY2tfUGxhY2Vob2xkZXIucG5nIiwiaWF0IjoxNzEzMjkzNDgwLCJleHAiOjE3NDQ4Mjk0ODB9.egED3GANHLnlQjj7jRRZB_mXXP02eLxK2XPqJAQ0wo4&t=2024-04-16T18%3A51%3A20.647Z",
    "imageClass": "output-image-datcats",

    "generationData": {
        "maxValue": 8880,
        "route": "monkeblock",
        "downloadName": "monkeblock",
        "needStyles": false
    }
};